import React from 'react';

const Footer = () => {
  return (
    <div id="footer">
      <div className="wrap">
        <p>Copyright &copy; 2023 60bpm.com</p>
      </div>
    </div>
  );
}

export default Footer;
