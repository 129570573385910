import React from 'react';

const Header = () => {
  return (
    <div id="header">
      <div className="wrap">
        <h1>Roaming Stones</h1>
      </div>
    </div>
  );
}

export default Header;
